<template>

    <div class="filter-bar" id="filter-bar" v-if="type === 'searchbox'">
        <div class="filter-bar-group">
            <input type="search" class="form-control" id="searchBar" v-model="string" v-on:keyup="updateString(string)" v-bind:placeholder="string === '' ? placeholderText : ''">
            <div class="filter-select" v-for="filter in filterSelects">
                <v-select :options="filter.options" v-model="selected[filter.name]" :onChange="updateSelect(filter.name, selected)" ></v-select>
            </div>
        </div>

        <div class="filter-bar-group">
            <div class="filter-bar-text">
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FilterBar",
        props: [
            'type',
            'placeholderText',
            'text',
            'filterString',
            'filterSelects',
            'updateString',
            'updateSelect',
        ],
        data() {
            let selected = {};
            for (let key in this.filterSelects) {
                if (this.filterSelects.hasOwnProperty(key)) {
                    let filter = this.filterSelects[key];
                    selected[filter.name] = filter.selected ? { value: filter.selected.value, label: filter.selected.label } : null;
                }
            }

            return {
                string: this.filterString,
                selected: selected
            }
        }
    }
</script>

<style scoped>
#searchBar {
    margin-right: 24px;
}
</style>
