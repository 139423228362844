const sidebarToggle = document.querySelector(".sidebar-toggle");

sidebarToggle.addEventListener("click", e => document.body.classList.toggle("sidebar-open"));

const menuToggles = document.querySelectorAll("aside a[data-toggle]");

function toggleMenu(event) {
    const button = event.currentTarget;
    console.log({button});
    button.classList.toggle("open");
}

for (let toggleName in menuToggles) {
    if (menuToggles.hasOwnProperty(toggleName)) {
        let mt = menuToggles[toggleName];
        mt.addEventListener("click", toggleMenu)
    }
}
