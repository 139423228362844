var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-bordered table-striped" }, [
    _c("thead", [
      _c(
        "tr",
        [
          _vm._l(_vm.columns, function(column) {
            return _c("th", [
              column.sortable === false
                ? _c("span", [_vm._v(_vm._s(column.name))])
                : column.sortable !== false &&
                  column.attribute === _vm.sortField
                ? _c(
                    "span",
                    {
                      class: "sort sort-" + _vm.sortDirection,
                      on: {
                        click: function($event) {
                          return _vm.sortMethod(column.attribute)
                        }
                      }
                    },
                    [_vm._v(_vm._s(column.name))]
                  )
                : _c(
                    "span",
                    {
                      staticClass: "sort",
                      on: {
                        click: function($event) {
                          return _vm.sortMethod(column.attribute)
                        }
                      }
                    },
                    [_vm._v(_vm._s(column.name))]
                  )
            ])
          }),
          _vm._v(" "),
          _vm.actions ? _c("th", [_c("span", [_vm._v("Actions")])]) : _vm._e()
        ],
        2
      )
    ]),
    _vm._v(" "),
    _vm.rows.length
      ? _c(
          "tbody",
          _vm._l(_vm.rows, function(row) {
            return _c(
              "tr",
              { key: row.identifier || row.id },
              [
                _vm._l(row, function(value, key) {
                  return key !== "ids" && key !== "id"
                    ? _c("td", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              typeof value === "boolean"
                                ? value
                                  ? "yes"
                                  : "no"
                                : value
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.actions
                  ? _c(
                      "td",
                      [
                        _vm._l(_vm.actions.buttons, function(button) {
                          return _vm.isButton(button, row)
                            ? [
                                _vm.isSimpleItem(button, row)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-primary btn-sm",
                                          staticStyle: {
                                            color: "white",
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            href:
                                              "/" +
                                              _vm.actions.prefix +
                                              "/" +
                                              row.id +
                                              "/" +
                                              (button === "view" ? "" : button)
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getIcon(button, row)
                                              )
                                            }
                                          }),
                                          _vm._v(_vm._s(button))
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isCompositeItem(button, row)
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-primary btn-sm",
                                          staticStyle: {
                                            color: "white",
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            href:
                                              "/" +
                                              _vm.actions.prefix +
                                              "/" +
                                              row.ids.id +
                                              "/" +
                                              (button === "view" ? "" : button)
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getIcon(button, row)
                                              )
                                            }
                                          }),
                                          _vm._v(_vm._s(button))
                                        ]
                                      )
                                    ]
                                  : _vm.isDeleteButton(button, row)
                                  ? [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary btn-sm",
                                          on: {
                                            click: function($event) {
                                              return _vm.onDelete(button, row)
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getIcon(button, row)
                                              )
                                            }
                                          }),
                                          _vm._v(" " + _vm._s(button.label))
                                        ]
                                      )
                                    ]
                                  : typeof button !== "string" &&
                                    row.ids[button.id] != null &&
                                    button.confirm != true
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-primary btn-sm",
                                          staticStyle: {
                                            color: "white",
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            href:
                                              "/" +
                                              button.prefix +
                                              "/" +
                                              row.ids[button.id] +
                                              "/" +
                                              (button.action === "view"
                                                ? ""
                                                : button.action)
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getIcon(button, row)
                                              )
                                            }
                                          }),
                                          _vm._v(_vm._s(button.label))
                                        ]
                                      )
                                    ]
                                  : typeof button !== "string" &&
                                    row.ids[button.id] != null &&
                                    button.confirm == true
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-primary btn-sm",
                                          staticStyle: {
                                            color: "white",
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            href: "#",
                                            "data-toggle": "modal",
                                            "data-target": "#confirm-modal",
                                            "data-name": row.name,
                                            "data-url":
                                              "/" +
                                              button.prefix +
                                              "/" +
                                              row.ids[button.id] +
                                              "/" +
                                              (button.action === "view"
                                                ? ""
                                                : button.action),
                                            "data-button": button.label
                                          }
                                        },
                                        [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getIcon(button, row)
                                              )
                                            }
                                          }),
                                          _vm._v(_vm._s(button.label))
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ],
              2
            )
          }),
          0
        )
      : _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "table-no-results",
                attrs: { colspan: _vm.columns.length + 1 }
              },
              [_vm._v("No matching results found")]
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }