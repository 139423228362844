import Chart from "chart.js";
import guard from "../util/guard";

guard(".charts", () => {
    const chartCanvases = document.querySelectorAll("canvas.list-distribution-chart");
    for (const canvasName in chartCanvases) {
        if (chartCanvases.hasOwnProperty(canvasName)) {
            let canvas = chartCanvases[canvasName];
            let distributionData = JSON.parse(canvas.dataset.distribution);
            let color = canvas.dataset.color;
            let data = {
                labels: [],
                datasets: [{
                    label: '',
                    data: [],
                    backgroundColor: color,
                }]
            };

            $.each(distributionData.values, function (index, value) {
                data.labels.push(distributionData.labels[distributionData.keys[index]]);
                data.datasets[0].data.push(value);
            });
            /*
            if(distributionData.invalid){
                data.labels.push('Invalid values');
                data.datasets[0].data.push(distributionData.invalid);
            }*/


            let options = {
                scales: {
                    xAxes: [{
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
                    }],
                    yAxes: [{
                        gridLines: {
                            color: "rgba(0, 0, 0, 0)",
                        }
                    }]
                },
                legend: {
                    display: false,
                },
                tooltips: {
                }
            };

            if (distributionData.mock === true) {
                options.tooltips.enabled = false;
            } else {
                options.tooltips.callbacks = {
                    title(tooltipItems, data) {
                        return '';
                    },

                    label(tooltipItems, data) {
                        const dataset = data.datasets[tooltipItems.datasetIndex].label;
                        const label = data.labels[tooltipItems.index];
                        const value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];

                        return `${label}: ${value}`;
                    },
                }
            }


            var barChart = new Chart(canvas.getContext("2d"), {
                type: 'bar',
                data: data,
                options: options
            });
        }
    };
});