var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("flex-table", {
        attrs: {
          type:
            "searchBox" in _vm.tableSettings ? _vm.tableSettings.searchBox : "",
          "placeholder-text":
            "placeholderText" in _vm.tableSettings
              ? _vm.tableSettings.placeholderText
              : "Type to filter...",
          text: this.text,
          columns: _vm.tableSettings.columns,
          actions:
            "actions" in _vm.tableSettings ? _vm.tableSettings.actions : [],
          sortDirection: this.sortDirection,
          sortField: this.sortField,
          updateString: this.updateString,
          updateSelect: this.updateSelect,
          filterSelects: this.filterSelects,
          filterString: this.filterString,
          setSort: this.setSort,
          setPage: this.setPage,
          rows: this.rows,
          rowCount: this.rowCount,
          pageCount: this.pageCount,
          pageNumber: this.page
        },
        on: { delete: _vm.onDelete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }