const widgets = document.querySelectorAll(".js-table-checkboxes");

if (widgets.length) {
    const findRow = checkbox => {
        let row = checkbox;

        while (row.tagName !== "TR") {
            row = row.parentNode;
        }

        return row;
    };

    const toggleRow = (row, checked) => {
        if (checked) {
            row.classList.add("row-checked");
        } else {
            row.classList.remove("row-checked");
        }
    };

    widgets.forEach(widget => {
        let allChecked = true;
        const allCheckboxes = widget.querySelector("tbody").querySelectorAll("input[type=checkbox]");
        const toggles = widget.querySelectorAll(".js-toggle-all");

        // 1. The individual checkboxes
        allCheckboxes.forEach(checkbox => {
            const tableRow = findRow(checkbox);

            allChecked = allChecked && checkbox.checked;

            toggleRow(tableRow, checkbox.checked);

            tableRow.addEventListener("click", event => {
                if (event.target.tagName !== "INPUT" || event.target.tagName !== "LABEL" ) {
                    checkbox.checked = !checkbox.checked;
                }

                toggleRow(tableRow, checkbox.checked);
            });

            checkbox.addEventListener("change", event => {
                toggleRow(tableRow, checkbox.checked);
            });
        });

        // 2. The toggle-all checkbox
        toggles.forEach(toggle => {
            toggle.checked = allChecked;

            toggle.addEventListener("change", event => {
                allCheckboxes.forEach(checkbox => {
                    const tableRow = findRow(checkbox);
                    checkbox.checked = toggle.checked;
                    toggleRow(tableRow, toggle.checked);
                });
            });
        });
    });
}
