/**
 * Call a function if the selector produces a result.
 *
 * @param {string} selector
 * @param {Function} callback
 */
export default function guard(selector, callback) {
    if (document.querySelectorAll(selector).length > 0) {
        callback();
    }
}
