<template>
    <div class="row">
        <div class="col-lg-12 margin-tb">
            <div class="pull-left">
                <h1 class="page-title">{{ title}}</h1>
            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: "PageTitle",
        props: ['title']
    }
</script>

<style scoped>

</style>