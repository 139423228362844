<template>
    <div>
        <filter-bar
            :type="type"
            :placeholderText="placeholderText"
            :text="text"
            :updateString="updateString"
            :updateSelect="updateSelect"
            :filterString="filterString"
            :filterSelects="filterSelects"
            :rowCount="rowCount"
        ></filter-bar>
        <table-content
            :columns="columns"
            :rows="rows"
            :actions="actions"
            :sortDirection="sortDirection"
            :rowCount="rowCount"
            :sortMethod="setSort"
            :sortField="sortField"
            @delete="onDelete($event)"
        ></table-content>
        <pagination
            :pageCount="pageCount"
            :setPageMethod="setPage"
            :pageNumber="pageNumber"
        ></pagination>
    </div>
</template>

<script>
    import FilterBar from './FilterBar';
    import TableContent from './TableContent';
    import Pagination from './Pagination';

    export default {
        name: "FlexTable",
        props: [
            'type',
            'placeholderText',
            'text',
            'columns',
            'actions',
            'applyFilter',
            'updateString',
            'updateSelect',
            'filterString',
            'filterSelects',
            'sortField',
            'sortDirection',
            'setSort',
            'setPage',
            'rows',
            'rowCount',
            'pageCount',
            'pageNumber'
        ],

        components: {
            FilterBar,
            TableContent,
            Pagination
        },

        methods: {
            onDelete(event) {
                this.$emit("delete", event);
            }
        }
    }
</script>
