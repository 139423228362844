let form = document.getElementById('advanced-search-form');
if (form !== null) {
    let pairs = {
        'document-type': 'document-type-options',
        'target-market': 'target-market-options',
        'investor-type': 'investor-type-options',
        'investor-type-retail': 'investor-type-retail-options',
        'investor-type-professional': 'investor-type-professional-options',
        'eligible-counterparty': 'eligible-counterparty-options',
        'risk-tolerance': 'risk-tolerance-options',
        'sri': 'sri-options',
        'srri': 'srri-options',
        'distribution-strategy': 'distribution-strategy-options',
        'execution-only': 'execution-only-options',
        'execution-with-test': 'execution-with-test-options',
        'investment-advice': 'investment-advice-options',
        'portfolio-management': 'portfolio-management-options',
    };

    let handleClick = (arrow, options) => {
        if (options.hasAttribute('hidden')) {
            options.removeAttribute('hidden');
            arrow.innerHTML = 'keyboard_arrow_up';
        } else {
            options.setAttribute('hidden', '');
            arrow.innerHTML = 'keyboard_arrow_down';
        }
    };

    for (let key in pairs) {
        let documentTypeHeader = document.getElementById(key);
        let arrow = documentTypeHeader.children[0].children[0];
        arrow.onclick = function (event) {
            let options = document.getElementById(pairs[key]);
            handleClick(arrow, options);
        };
    }


}