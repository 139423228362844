import * as FilePond from "filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";

const disseminationUploadForm = document.querySelector("#dissemination-upload-form");
if (disseminationUploadForm) {
    const uploadButton = document.querySelector("#btn-upload");
    const filepond = FilePond.create(document.querySelector("#file"), {
        instantUpload: false,
        allowRevert: false,
        labelFileProcessingError: (error) => {
            return error.body;
        },
        server: {
            process: {
                headers: {
                    "X-CSRF-Token": document.querySelector('input[name=_token]').value,
                    "Accept": "application/json",
                },
                onerror: (response) => {
                    let result = JSON.parse(response);
                    if ('errors' in result) {
                        if ('file' in result.errors) {
                            if (result.errors.file.length > 0) {
                                return result.errors.file[0];
                            }
                        }
                    }

                    return 'Error uploading file';
                },
            },

        },
    });

    uploadButton.onclick = function (e) {
        e.preventDefault();
        filepond.processFiles();
    }

    const pond = document.querySelector('.filepond--root');
    pond.addEventListener('FilePond:addfile', e => {
        for (var i = 0; i < filepond.getFiles().length; i++) {
            //TODO add some feedback
        }

        //if we have files that have not been uploaded show the upload button
        uploadButton.classList.remove('disabled');

        //if we have files uploaded show the view files button

    });
}