const fieldFilter = document.querySelector("#field-filter");

if (fieldFilter) {
    const fields = [...document.querySelectorAll(".template-field")].map(el => {
        return {
            el,
            label: el.querySelector("label").textContent,
        };
    });

    function filterFields(ev) {
        const filter = fieldFilter.value;

        if (filter) {
            const rx = new RegExp(filter, "i");

            for (let fieldName in fields) {
                if (fields.hasOwnProperty(fieldName)) {
                    let f = fields[fieldName];
                    if (rx.test(f.label)) {
                        f.el.classList.remove("filter-out");
                    } else {
                        f.el.classList.add("filter-out");
                    }
                }
            }
        } else {
            for (let fieldName in fields) {
                if (fields.hasOwnProperty(fieldName)) {
                    let f = fields[fieldName];
                    f.el.classList.remove("filter-out");
                }
            }
        }
    }

    fieldFilter.addEventListener("change", filterFields);
    fieldFilter.addEventListener("keyup", filterFields);
}
