const template = document.querySelector('#text-balloon');
const elements = document.querySelectorAll('.authorizable');

for (let key in elements) {
    if (elements.hasOwnProperty(key)) {
        const element = elements[key];

        if (element.getAttribute('data-allowed') !== "yes") {
            element.addEventListener('click', function (event) {
                event.preventDefault();
                let text = element.getAttribute('data-text');

                const es = document.querySelectorAll('.text-balloon');

                for (let k in es) {
                    if (es.hasOwnProperty(k)) {
                        if (es[k].id === '') {
                            es[k].outerHTML = '';
                        }
                    }
                }

                const balloon = template.cloneNode(true);
                window.bloon = balloon;
                balloon.childNodes[3].textContent = text;
                balloon.removeAttribute('hidden');
                balloon.removeAttribute('id');

                const icon = balloon.getElementsByClassName('clear-icon')[0];
                icon.addEventListener('click', function (event) {
                    event.currentTarget.parentNode.parentNode.remove();
                });

                element.after(balloon);

                new Popper(element, balloon, {
                    modifiers: {
                        arrow: {
                            enabled: true,
                            element: ".popper-arrow",
                        }
                    }
                });
            });
        }
    }
}

