<docs>
     <!-- Documentation on the json object that is required and optionals -->
    pageTitle, string, optional.
        Example: { pageTitle: "I am a page title" }

    searchBox, enumeration, optional. Options: ['searchbox']
        Example: { searchBox: "searchbox" }
    placeholderText, string, optional. Default: "Type to filter..
        Example: { placeholderText: "I am displayed if nothing is typed" }

    searchText, string, optional
    customSearch, object optional, which appends to the GET url where it adds &{key}={value} per property of the object.
        Example: {
            companyId: 4,
            companyName: 'Shinra'
        }
    columns: array, required.
        Example: {
            name: "Column name"
            attribute: "I will be searched for in an API call" // required if sortable
            sortable: false // optional. Defaults to true
        }, {
            name: "Other column"
            attribute: "Other attribute"
        }
    filters: array, optional. options must be in ID label pairs with the exception of 0.
        Example: {
            name: "Issuer groups,
            options: {
                0: '',
                1: 'Amundi',
                2: 'Deutsche'
            },
            selected: 1
        }
    actions: array, optional
        Example: {
            prefix: "user", // This will prefix the url before the id.
            buttons: ["view", "edit", "update", "delete"] // these are the 4 options available so far, they all go to /{prefix}/{id}/{buttonName}
        }
    sortDirection: enum, optional, defaults to "asc"
        Example: { sortDirection: "desc" }
    sortField: string, optional, defaults to first column attribute specified above
        Example: { sortField: "lastName" }
    numberOfResults: int, optional, defaults to 25
        Example: { numberOfResults: 10 }
</docs>

<template>
    <div>
        <flex-table
                :type="searchBox"
                :placeholder-text="placeholderText"
                :text="text"
                :columns="columns"
                :actions="actions"
                :sortDirection="sortDirection"
                :sortField=sortField
                :updateString="updateString"
                :updateSelect="updateSelect"
                :filterSelects="filterSelects"
                :filterString="filterString"
                :setSort="setSort"
                :setPage="setPage"
                :rows="rows"
                :rowCount="rowCount"
                :pageCount="pageCount"
                :pageNumber="page"
                @delete="onDelete"
        ></flex-table>
    </div>
</template>

<script>
    import axios from "axios";
    import PageTitle from './PageTitle';
    import FlexTable from './FlexTable';

    export default {
        // props: [
        //     'tableSettings',
        // ],
        props: {
            numberOfResults: {type: Number, default: 25},
            filter: {type: String, default: ""},
            pageTitle: {type: String, default: ""},
            searchBox: {type: String, default: ""},
            placeholderText: {type: String, default: "Type to filter..."},
            searchText: {type: String, default: ""},
            customSearch: {type: Object},
            columns: {type: Array},
            filters: {type: Array},
            actions: {type: Array},
            sortDirection: {type: String, default: "asc"},
            sortField: {type: String},
        },

        data() {
            return {
                textReplacement: null,
                rows: [],
                rowCount: 0,
                pageCount: 0,
                page: 1,
                text: "",
                filterSelects: [],
                filterString: this.filter,
                sortingField: this.sortField || this.columns[0].name,
            }
        },

        components: {
            PageTitle,
            FlexTable,
        },

        computed: {
            response() {
                this.getRows();
            },
        },

        methods: {
            getRows () {
                let url = window.location.pathname + `/search?orderBy=${this.sortField}&sortDirection=${this.sortDirection}&filter=${this.filterString}&page=${this.page}&numberOfResults=${this.numberOfResults}`;

                for (let key in this.customSearch) {
                    if (this.customSearch.hasOwnProperty(key) && this.customSearch[key] != 0 && typeof this.customSearch[key] !== "undefined") {
                        url+= '&' + key + '=' + this.customSearch[key];
                    }
                }

                const text = this.text;

                axios.get(url)
                    .then(r => {
                        const data = r.data;
                        console.log({data});

                        this.rowCount = data.numberOfResults;
                        this.rows = data.rows;
                        this.pageCount = Math.ceil(this.rowCount / this.numberOfResults);

                        if (this.text.indexOf('{{rowCount}}') >= 0) {
                            this.text = this.text.replace('{{rowCount}}', this.rowCount);
                            this.textReplacement = ' ' + this.rowCount + ' ';
                        }
                        if (this.textReplacement !== null) {
                            this.text = this.text.replace(this.textReplacement, ' ' + this.rowCount + ' ');
                            this.textReplacement = ' ' + this.rowCount + ' ';
                        }
                    });
            },

            updateSelect (id, val) {
                this.customSearch[id] = (val[id] === null) ? 0 : val[id].value;
                this.getRows();
            },

            updateString (value) {
                this.filterString = value;
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.getRows();
                }, 400);
            },

            setPage(number) {
                this.page = number;
                this.getRows();
            },

            setSort (sortField) {
                if (sortField === this.sortField) {
                    if (this.sortDirection === "asc") {
                        this.sortDirection = "desc";
                    } else {
                        this.sortDirection = "asc";
                    }
                } else {
                    this.sortField = sortField;
                    this.sortDirection = "asc";
                }
                this.getRows();
            },

            sortHeaderClass (sortField) {
                if (sortField === this.sortField) {
                    return `sort-${this.sortDirection}`;
                }

                return "";
            },

            onDelete(event) {
                axios(event.url)
                    .then(r => {
                        this.getRows();
                    });
                // console.log(`1. Make a POST request to ${event.url}`);
                // console.log(`2. Call this.getRows()`);
            }
        },

        created () {
            console.log(this.numberOfResults);
            console.log(this.filter);
            console.log(this.pageTitle);
            console.log(this.searchBox);
            console.log(this.placeholderText);
            console.log(this.searchText);
            console.log(this.customSearch);
            console.log(this.columns);
            console.log(this.filters);
            console.log(this.actions);
            console.log(this.sortDirection);
            console.log(this.sortField);
            this.getRows();
        },
    };
</script>

<style scoped>

</style>
