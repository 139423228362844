<docs>
     <!-- Documentation on the json object that is required and optionals -->
    pageTitle, string, optional.
        Example: { pageTitle: "I am a page title" }

    searchBox, enumeration, optional. Options: ['searchbox']
        Example: { searchBox: "searchbox" }
    placeholderText, string, optional. Default: "Type to filter..
        Example: { placeholderText: "I am displayed if nothing is typed" }

    searchText, string, optional
    customSearch, object optional, which appends to the GET url where it adds &{key}={value} per property of the object.
        Example: {
            companyId: 4,
            companyName: 'Shinra'
        }
    columns: array, required.
        Example: {
            name: "Column name"
            attribute: "I will be searched for in an API call" // required if sortable
            sortable: false // optional. Defaults to true
        }, {
            name: "Other column"
            attribute: "Other attribute"
        }
    filters: array, optional. options must be in ID label pairs with the exception of 0.
        Example: {
            name: "Issuer groups,
            options: {
                0: '',
                1: 'Amundi',
                2: 'Deutsche'
            },
            selected: 1
        }
    actions: array, optional
        Example: {
            prefix: "user", // This will prefix the url before the id.
            buttons: ["view", "edit", "update", "delete"] // these are the 4 options available so far, they all go to /{prefix}/{id}/{buttonName}
        }
    sortDirection: enum, optional, defaults to "asc"
        Example: { sortDirection: "desc" }
    sortField: string, optional, defaults to first column attribute specified above
        Example: { sortField: "lastName" }
    numberOfResults: int, optional, defaults to 25
        Example: { numberOfResults: 10 }
</docs>

<template>
    <div>
        <flex-table
                :type="`searchBox` in tableSettings ? tableSettings.searchBox : ''"
                :placeholder-text="`placeholderText` in tableSettings ? tableSettings.placeholderText : 'Type to filter...'"
                :text="this.text"
                :columns="tableSettings.columns"
                :actions="`actions` in tableSettings ? tableSettings.actions : []"
                :sortDirection="this.sortDirection"
                :sortField=this.sortField
                :updateString="this.updateString"
                :updateSelect="this.updateSelect"
                :filterSelects="this.filterSelects"
                :filterString="this.filterString"
                :setSort="this.setSort"
                :setPage="this.setPage"
                :rows="this.rows"
                :rowCount="this.rowCount"
                :pageCount="this.pageCount"
                :pageNumber="this.page"
                @delete="onDelete"
        ></flex-table>
    </div>
</template>

<script>
    import axios from "axios";
    import PageTitle from './PageTitle';
    import FlexTable from './FlexTable';
    import $ from "jquery";

    export default {
        props: [
            'tableSettings',
        ],
        data() {
            return {
                numberOfResults: `numberOfResults` in this.tableSettings ? this.tableSettings.numberOfResults : 25,
                filterString: `filter` in this.tableSettings ? this.tableSettings.filter : '' ,
                filterSelects: `filters` in this.tableSettings ? this.tableSettings.filters : {},
                text: `searchText` in this.tableSettings ? this.tableSettings.searchText : '',
                customSearch: 'customSearch' in this.tableSettings ? this.tableSettings.customSearch : {},
                textReplacement: null,
                rows: [],
                rowCount: 0,
                pageCount: 0,
                page: 1,
                sortDirection: `sortDirection` in this.tableSettings ? this.tableSettings.sortDirection : 'asc',
                sortField: `sortField` in this.tableSettings ? this.tableSettings.sortField : this.tableSettings.columns[0].attribute
            }
        },
        components: {
            PageTitle,
            FlexTable
        },

        computed: {
            response() {
                this.getRows();
            }
        },

        methods: {
            getRows () {
                let url = window.location.pathname + `/search?orderBy=${this.sortField}&sortDirection=${this.sortDirection}&filter=${this.filterString}&page=${this.page}&numberOfResults=${this.numberOfResults}`;
                for (let key in this.customSearch) {
                    if (this.customSearch.hasOwnProperty(key) && this.customSearch[key] != 0 && typeof this.customSearch[key] !== "undefined") {
                        url+= '&' + key + '=' + this.customSearch[key];
                    }
                }

                const text = this.text;
                var scope = this;
                $.ajax({
                    url: url,
                    success: function (data) {
                        scope.rowCount = data.numberOfResults;
                        scope.rows = data.rows;
                        scope.pageCount = Math.ceil(scope.rowCount / scope.numberOfResults);

                        if (scope.text.indexOf('{{rowCount}}') >= 0) {
                            scope.text = scope.text.replace('{{rowCount}}', scope.rowCount);
                            scope.textReplacement = ' ' + scope.rowCount + ' ';
                        }
                        if (scope.textReplacement !== null) {
                            scope.text = scope.text.replace(scope.textReplacement, ' ' + scope.rowCount + ' ');
                            scope.textReplacement = ' ' + scope.rowCount + ' ';
                        }
                    }
                });
            },

            updateSelect (id, val) {
                this.customSearch[id] = (val[id] === null) ? 0 : val[id].value;
                this.getRows();
            },
            updateString (value) {
                this.filterString = value;
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.timer = setTimeout(() => {
                    this.getRows();
                }, 400);
            },
            setPage(number) {
                this.page = number;
                this.getRows();
            },
            setSort (sortField) {
                if (sortField === this.sortField) {
                    if (this.sortDirection === "asc") {
                        this.sortDirection = "desc";
                    } else {
                        this.sortDirection = "asc";
                    }
                } else {
                    this.sortField = sortField;
                    this.sortDirection = "asc";
                }
                this.getRows();
            },
            sortHeaderClass (sortField) {
                if (sortField === this.sortField) {
                    return `sort-${this.sortDirection}`;
                }

                return "";
            },
            onDelete(event) {
                axios(event.url)
                    .then(r => {
                        this.getRows();
                    });
                // console.log(`1. Make a POST request to ${event.url}`);
                // console.log(`2. Call this.getRows()`);
            }
        },

        created () {
            this.getRows();
        },
    }
</script>

<style scoped>

</style>
