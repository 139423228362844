var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "searchbox"
    ? _c("div", { staticClass: "filter-bar", attrs: { id: "filter-bar" } }, [
        _c(
          "div",
          { staticClass: "filter-bar-group" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.string,
                  expression: "string"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "search",
                id: "searchBar",
                placeholder: _vm.string === "" ? _vm.placeholderText : ""
              },
              domProps: { value: _vm.string },
              on: {
                keyup: function($event) {
                  return _vm.updateString(_vm.string)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.string = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _vm._l(_vm.filterSelects, function(filter) {
              return _c(
                "div",
                { staticClass: "filter-select" },
                [
                  _c("v-select", {
                    attrs: {
                      options: filter.options,
                      onChange: _vm.updateSelect(filter.name, _vm.selected)
                    },
                    model: {
                      value: _vm.selected[filter.name],
                      callback: function($$v) {
                        _vm.$set(_vm.selected, filter.name, $$v)
                      },
                      expression: "selected[filter.name]"
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "filter-bar-group" }, [
          _c("div", { staticClass: "filter-bar-text" }, [
            _vm._v("\n            " + _vm._s(_vm.text) + "\n        ")
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }