var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("filter-bar", {
        attrs: {
          type: _vm.type,
          placeholderText: _vm.placeholderText,
          text: _vm.text,
          updateString: _vm.updateString,
          updateSelect: _vm.updateSelect,
          filterString: _vm.filterString,
          filterSelects: _vm.filterSelects,
          rowCount: _vm.rowCount
        }
      }),
      _vm._v(" "),
      _c("table-content", {
        attrs: {
          columns: _vm.columns,
          rows: _vm.rows,
          actions: _vm.actions,
          sortDirection: _vm.sortDirection,
          rowCount: _vm.rowCount,
          sortMethod: _vm.setSort,
          sortField: _vm.sortField
        },
        on: {
          delete: function($event) {
            return _vm.onDelete($event)
          }
        }
      }),
      _vm._v(" "),
      _c("pagination", {
        attrs: {
          pageCount: _vm.pageCount,
          setPageMethod: _vm.setPage,
          pageNumber: _vm.pageNumber
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }