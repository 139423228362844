<template>
    <div class="pagination-component">
        <ul class="pagination" role="navigation" v-if="pageCount > 1 && pageCount <= 11">
            <li v-if="pageNumber === 1" class="page-item disabled" aria-label="previous"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber - 1)"><</span></li>
            <li v-if="pageNumber > 1" class="page-item" aria-label="previous"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber - 1)"><</span></li>

            <li v-for="n in pageNumber - 1" class="page-item"><span class="page-link" @click="setPageMethod(n)">{{ n }}</span></li>
            <li class="page-item active"><span class="page-link">{{ pageNumber }}</span></li>
            <li v-for="n in pageCount - pageNumber" class="page-item"><span class="page-link" @click="setPageMethod(pageNumber + n)">{{ pageNumber + n }}</span></li>

            <li v-if="pageNumber === pageCount" class="page-item disabled" aria-label="next"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber + 1)">></span></li>
            <li v-if="pageNumber < pageCount" class="page-item" aria-label="next"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber + 1)">></span></li>
        </ul>
        <ul class="pagination" role="navigation" v-if="pageCount > 11 && pageNumber <= 6">
            <li v-if="pageNumber === 1" class="page-item disabled" aria-label="previous"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber - 1)"><</span></li>
            <li v-if="pageNumber > 1" class="page-item" aria-label="previous"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber - 1)"><</span></li>

            <li v-for="n in pageNumber - 1" class="page-item"><span class="page-link" @click="setPageMethod(n)">{{ n }}</span></li>
            <li class="page-item active"><span class="page-link">{{ pageNumber }}</span></li>
            <li v-for="n in 8 - pageNumber" class="page-item"><span class="page-link" @click="setPageMethod(pageNumber + n)">{{ pageNumber + n }}</span></li>

            <li class="page-item disabled" aria-disabled="true"><span class="page-link">...</span></li>

            <li v-for="n in 2" class="page-item"><span class="page-link" @click="setPageMethod(pageCount - 2 + n)">{{ pageCount - 2 + n}}</span></li>
            <li class="page-item" aria-label="next"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber + 1)">></span></li>
        </ul>

        <ul class="pagination" role="navigation" v-if="pageCount > 11 && pageNumber > 6 && pageNumber < (pageCount - 5)">
            <li v-if="pageNumber > 1" class="page-item" aria-label="previous"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber - 1)" ><</span></li>
            <li v-for="n in 2" class="page-item"><span class="page-link" @click="setPageMethod(n)">{{ n }}</span></li>
            <li class="page-item disabled" aria-disabled="true"><span class="page-link">...</span></li>
            <li v-for="n in 2" class="page-item"><span class="page-link" @click="setPageMethod(pageNumber - 3 + n)">{{ pageNumber - 3 + n }}</span></li>
            <li class="page-item active"><span class="page-link">{{ pageNumber }}</span></li>
            <li v-for="n in 2" class="page-item"><span class="page-link" @click="setPageMethod(pageNumber + n)">{{ pageNumber + n }}</span></li>
            <li class="page-item disabled" aria-disabled="true"><span class="page-link">...</span></li>
            <li v-for="n in 2" class="page-item"><span class="page-link" @click="setPageMethod(pageCount - 2 + n)">{{ pageCount - 2 + n }}</span></li>
            <li class="page-item" aria-label="next"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber + 1)">></span></li>

        </ul>

        <ul class="pagination" role="navigation" v-if="pageCount > 11 && pageNumber >= (pageCount - 5)">
            <li class="page-item" aria-label="previous"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber - 1)"><</span></li>
            <li v-for="n in 2" class="page-item"><span class="page-link" @click="setPageMethod(n)">{{ n }}</span></li>
            <li class="page-item disabled" aria-disabled="true"><span class="page-link">...</span></li>
            <li v-for="n in 7 - (pageCount - pageNumber)" class="page-item"><span class="page-link" @click="setPageMethod(pageCount - 8 + n)">{{ pageCount - 8 + n }}</span></li>
            <li class="page-item active"><span class="page-link">{{ pageNumber }}</span></li>
            <li v-for="n in pageCount - pageNumber" class="page-item"><span class="page-link" @click="setPageMethod(pageNumber + n)">{{ pageNumber + n }}</span></li>
            <li v-if="pageNumber === pageCount" class="page-item disabled" aria-label="next"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber + 1)">></span></li>
            <li v-if="pageNumber < pageCount" class="page-item" aria-label="next"><span class="page-link" aria-hidden="true" @click="setPageMethod(pageNumber + 1)">></span></li>

        </ul>
    </div>
</template>

<script>
    export default {
        props: [
            'setPageMethod',
            'pageCount',
            'pageNumber'
        ],
    }
</script>

<style scoped>

</style>