<template>
    <table class="table table-bordered table-striped">
        <thead>
        <tr>
            <th v-for="column in columns">
                <span v-if="column.sortable === false">{{ column.name }}</span>
                <span v-else-if="column.sortable !== false && column.attribute === sortField" :class="'sort sort-' + sortDirection" @click="sortMethod(column.attribute)">{{ column.name }}</span>
                <span v-else class="sort" @click="sortMethod(column.attribute)">{{ column.name }}</span>
            </th>
            <th v-if="actions">
                <span>Actions</span>
            </th>
        </tr>
        </thead>

        <tbody v-if="rows.length">
        <tr v-for="row in rows" :key="row.identifier || row.id">
            <td v-for="(value, key) in row" v-if="key !== 'ids' && key !== 'id'">
                {{ typeof(value) === "boolean" ? (value ? "yes" : "no") : value }}
            </td>
            <!--<td>-->
                <!--<a class="btn btn-sm btn-info" :href="row.url">Show</a>-->
            <!--</td>-->
            <td v-if="actions">
                <template v-for="button in actions.buttons" v-if="isButton(button, row)" style="margin-right: 5px;" class="float-right">
                    <template v-if="isSimpleItem(button, row)">
                        <a :href="'/' + actions.prefix + '/' + row.id + '/'+ (button === 'view' ? '' : button)"
                           style="color: white; text-transform: capitalize;"
                           class="btn btn-primary btn-sm"><span v-html="getIcon(button, row)"></span>{{ button }}</a>
                    </template>
                    <template v-if="isCompositeItem(button, row)">
                        <a :href="'/' + actions.prefix + '/' + row.ids.id + '/'+ (button === 'view' ? '' : button)"
                           style="color: white; text-transform: capitalize;"
                           class="btn btn-primary btn-sm"><span v-html="getIcon(button, row)"></span>{{ button }}</a>
                    </template>
                    <template v-else-if="isDeleteButton(button, row)">
                        <button class="btn btn-primary btn-sm" @click="onDelete(button, row)"><span v-html="getIcon(button, row)"></span> {{button.label}}</button>
                    </template>
                    <template v-else-if="typeof button !== 'string' && row.ids[button.id] != null && button.confirm != true">
                        <a :href="'/' + button.prefix + '/' + row.ids[button.id] + '/'+ (button.action === 'view' ? '' : button.action)"
                           style="color: white; text-transform: capitalize;"
                           class="btn btn-primary btn-sm"><span v-html="getIcon(button, row)"></span>{{ button.label }}</a>
                    </template>
                    <template v-else-if="typeof button !== 'string' && row.ids[button.id] != null && button.confirm == true">
                        <a href="#"
                           data-toggle="modal"
                           data-target="#confirm-modal"
                           :data-name="row.name" :data-url="'/' + button.prefix + '/' + row.ids[button.id] + '/'+ (button.action === 'view' ? '' : button.action)"
                           :data-button="button.label"
                           style="color: white; text-transform: capitalize;" class="btn btn-primary btn-sm"><span v-html="getIcon(button, row)"></span>{{ button.label }}</a>
                    </template>

                </template>
            </td>
        </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td class="table-no-results" :colspan="columns.length + 1">No matching results found</td>
            </tr>
        </tbody>
    </table>

    <!--<div v-html="links" @click="changePage"></div>-->
</template>

<script>
    export default {
        name: "TableContent",
        props: [
            "columns",
            "rowCount",
            "rows",
            'actions',
            "sortMethod",
            "sortDirection",
            "sortField",
        ],
        methods: {
            isButton(button, row) {
                if (typeof button == "string") {
                    return row.id !== null;
                }

                if (button instanceof Object) {
                    return row.ids[button.id] !== null;
                }

                return false;
            },

            isSimpleItem(button, row) {
                return typeof button === 'string' && row.id != null;
            },

            isCompositeItem(button, row) {
                return typeof button === 'string' && row.ids != null;
            },

            isDeleteButton(button, row) {
                return button.action === 'delete';
            },
            getIcon(button, row){
                let label = "";
                if (typeof button == "string") {
                    label = button;
                }else{
                    label = button.action;
                }
                switch(label) {
                    case "download":
                        return '<i class="fas fa-download"></i> ';
                    case "delete":
                        return '<i class="far fa-trash-alt"></i> ';
                    case "view":
                        return '<i class="far fa-eye"></i> ';
                    case "edit":
                        return '<i class="far fa-edit"></i> ';
                    case "instrument":
                    case "instruments":
                    case "list":
                        return '<i class="fas fa-list"></i> ';
                }
            },

            onDelete(button, row) {
                this.$confirm("Are you sure you want to delete this?").then(() => {
                    const url = `/${button.prefix}/${row.ids[button.id]}/${button.action}`;
                    this.$emit("delete", {
                        item: row,
                        url,
                    });
                });
            },
        },
    }
</script>
