var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12 margin-tb" }, [
      _c("div", { staticClass: "pull-left" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v(_vm._s(_vm.title))])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }