var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-component" }, [
    _vm.pageCount > 1 && _vm.pageCount <= 11
      ? _c(
          "ul",
          { staticClass: "pagination", attrs: { role: "navigation" } },
          [
            _vm.pageNumber === 1
              ? _c(
                  "li",
                  {
                    staticClass: "page-item disabled",
                    attrs: { "aria-label": "previous" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber - 1)
                          }
                        }
                      },
                      [_vm._v("<")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageNumber > 1
              ? _c(
                  "li",
                  {
                    staticClass: "page-item",
                    attrs: { "aria-label": "previous" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber - 1)
                          }
                        }
                      },
                      [_vm._v("<")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pageNumber - 1, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(n))]
                )
              ])
            }),
            _vm._v(" "),
            _c("li", { staticClass: "page-item active" }, [
              _c("span", { staticClass: "page-link" }, [
                _vm._v(_vm._s(_vm.pageNumber))
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.pageCount - _vm.pageNumber, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageNumber + n))]
                )
              ])
            }),
            _vm._v(" "),
            _vm.pageNumber === _vm.pageCount
              ? _c(
                  "li",
                  {
                    staticClass: "page-item disabled",
                    attrs: { "aria-label": "next" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber + 1)
                          }
                        }
                      },
                      [_vm._v(">")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageNumber < _vm.pageCount
              ? _c(
                  "li",
                  { staticClass: "page-item", attrs: { "aria-label": "next" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber + 1)
                          }
                        }
                      },
                      [_vm._v(">")]
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.pageCount > 11 && _vm.pageNumber <= 6
      ? _c(
          "ul",
          { staticClass: "pagination", attrs: { role: "navigation" } },
          [
            _vm.pageNumber === 1
              ? _c(
                  "li",
                  {
                    staticClass: "page-item disabled",
                    attrs: { "aria-label": "previous" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber - 1)
                          }
                        }
                      },
                      [_vm._v("<")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageNumber > 1
              ? _c(
                  "li",
                  {
                    staticClass: "page-item",
                    attrs: { "aria-label": "previous" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber - 1)
                          }
                        }
                      },
                      [_vm._v("<")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pageNumber - 1, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(n))]
                )
              ])
            }),
            _vm._v(" "),
            _c("li", { staticClass: "page-item active" }, [
              _c("span", { staticClass: "page-link" }, [
                _vm._v(_vm._s(_vm.pageNumber))
              ])
            ]),
            _vm._v(" "),
            _vm._l(8 - _vm.pageNumber, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageNumber + n))]
                )
              ])
            }),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._l(2, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageCount - 2 + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageCount - 2 + n))]
                )
              ])
            }),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "page-item", attrs: { "aria-label": "next" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    attrs: { "aria-hidden": "true" },
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber + 1)
                      }
                    }
                  },
                  [_vm._v(">")]
                )
              ]
            )
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.pageCount > 11 &&
    _vm.pageNumber > 6 &&
    _vm.pageNumber < _vm.pageCount - 5
      ? _c(
          "ul",
          { staticClass: "pagination", attrs: { role: "navigation" } },
          [
            _vm.pageNumber > 1
              ? _c(
                  "li",
                  {
                    staticClass: "page-item",
                    attrs: { "aria-label": "previous" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber - 1)
                          }
                        }
                      },
                      [_vm._v("<")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(2, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(n))]
                )
              ])
            }),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._l(2, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber - 3 + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageNumber - 3 + n))]
                )
              ])
            }),
            _vm._v(" "),
            _c("li", { staticClass: "page-item active" }, [
              _c("span", { staticClass: "page-link" }, [
                _vm._v(_vm._s(_vm.pageNumber))
              ])
            ]),
            _vm._v(" "),
            _vm._l(2, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageNumber + n))]
                )
              ])
            }),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._l(2, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageCount - 2 + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageCount - 2 + n))]
                )
              ])
            }),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "page-item", attrs: { "aria-label": "next" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    attrs: { "aria-hidden": "true" },
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber + 1)
                      }
                    }
                  },
                  [_vm._v(">")]
                )
              ]
            )
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.pageCount > 11 && _vm.pageNumber >= _vm.pageCount - 5
      ? _c(
          "ul",
          { staticClass: "pagination", attrs: { role: "navigation" } },
          [
            _c(
              "li",
              { staticClass: "page-item", attrs: { "aria-label": "previous" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    attrs: { "aria-hidden": "true" },
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber - 1)
                      }
                    }
                  },
                  [_vm._v("<")]
                )
              ]
            ),
            _vm._v(" "),
            _vm._l(2, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(n))]
                )
              ])
            }),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _vm._l(7 - (_vm.pageCount - _vm.pageNumber), function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageCount - 8 + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageCount - 8 + n))]
                )
              ])
            }),
            _vm._v(" "),
            _c("li", { staticClass: "page-item active" }, [
              _c("span", { staticClass: "page-link" }, [
                _vm._v(_vm._s(_vm.pageNumber))
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.pageCount - _vm.pageNumber, function(n) {
              return _c("li", { staticClass: "page-item" }, [
                _c(
                  "span",
                  {
                    staticClass: "page-link",
                    on: {
                      click: function($event) {
                        return _vm.setPageMethod(_vm.pageNumber + n)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.pageNumber + n))]
                )
              ])
            }),
            _vm._v(" "),
            _vm.pageNumber === _vm.pageCount
              ? _c(
                  "li",
                  {
                    staticClass: "page-item disabled",
                    attrs: { "aria-label": "next" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber + 1)
                          }
                        }
                      },
                      [_vm._v(">")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pageNumber < _vm.pageCount
              ? _c(
                  "li",
                  { staticClass: "page-item", attrs: { "aria-label": "next" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "page-link",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function($event) {
                            return _vm.setPageMethod(_vm.pageNumber + 1)
                          }
                        }
                      },
                      [_vm._v(">")]
                    )
                  ]
                )
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "page-item disabled", attrs: { "aria-disabled": "true" } },
      [_c("span", { staticClass: "page-link" }, [_vm._v("...")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "page-item disabled", attrs: { "aria-disabled": "true" } },
      [_c("span", { staticClass: "page-link" }, [_vm._v("...")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "page-item disabled", attrs: { "aria-disabled": "true" } },
      [_c("span", { staticClass: "page-link" }, [_vm._v("...")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "page-item disabled", attrs: { "aria-disabled": "true" } },
      [_c("span", { staticClass: "page-link" }, [_vm._v("...")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }