import { render, staticRenderFns } from "./VueTable.vue?vue&type=template&id=710b7693&scoped=true&"
import script from "./VueTable.vue?vue&type=script&lang=js&"
export * from "./VueTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710b7693",
  null
  
)

/* custom blocks */
import block0 from "./VueTable.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/bridge/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('710b7693')) {
      api.createRecord('710b7693', component.options)
    } else {
      api.reload('710b7693', component.options)
    }
    module.hot.accept("./VueTable.vue?vue&type=template&id=710b7693&scoped=true&", function () {
      api.rerender('710b7693', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/generic/table/VueTable.vue"
export default component.exports