import Chart from "chart.js";
import guard from "../util/guard";

guard(".charts", () => {
    const chartCanvases = document.querySelectorAll("canvas.list-coverage-chart");

    for (let canvasName in chartCanvases) {
        if (chartCanvases.hasOwnProperty(canvasName)) {
            let canvas = chartCanvases[canvasName];
            let percentages = JSON.parse(canvas.dataset.percentages);
            let settings = {
                type: "doughnut",
                data: {
                    labels: ["covered", "missing"],
                    datasets: [{
                        label: "EMT",
                        data: [percentages.emt, 1 - percentages.emt],
                        backgroundColor: [
                            "rgba(184, 47, 73, 1)",
                            "rgba(184, 47, 73, 0.05)",
                        ],
                    }, {
                        label: "EPT",
                        data: [percentages.ept, 1 - percentages.ept],
                        backgroundColor: [
                            "rgba(77, 145, 179, 1)",
                            "rgba(77, 145, 179, 0.05)",
                        ],
                    }]
                },
                options: {
                    legend: {
                        display: false,
                    },
                    tooltips: {
                    }
                }
            };

            if (percentages.mock === true) {
                settings.options.tooltips.enabled = false;
            } else {
                settings.options.tooltips.callbacks = {
                    label(tooltipItems, data) {
                        const dataset = data.datasets[tooltipItems.datasetIndex].label;
                        const label = data.labels[tooltipItems.index];
                        const value = Math.round(10000 * data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]) / 100;

                        return `${dataset} ${label}: ${value}%`;
                    },
                }
            }

            let chart = new Chart(canvas.getContext("2d"), settings);
        }
    };
});