var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("flex-table", {
        attrs: {
          type: _vm.searchBox,
          "placeholder-text": _vm.placeholderText,
          text: _vm.text,
          columns: _vm.columns,
          actions: _vm.actions,
          sortDirection: _vm.sortDirection,
          sortField: _vm.sortField,
          updateString: _vm.updateString,
          updateSelect: _vm.updateSelect,
          filterSelects: _vm.filterSelects,
          filterString: _vm.filterString,
          setSort: _vm.setSort,
          setPage: _vm.setPage,
          rows: _vm.rows,
          rowCount: _vm.rowCount,
          pageCount: _vm.pageCount,
          pageNumber: _vm.page
        },
        on: { delete: _vm.onDelete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }