/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using     Vue and Laravel.
 */
import Vue from "vue";
import VueSelect from "vue-select";
import VueSimpleAlert from "vue-simple-alert";
// import "vue-select/dist/vue-select.css";
import 'babel-polyfill';
import 'custom-event-polyfill';

import _ from 'lodash';

Object.defineProperty(Vue.prototype, '$_', { value: _ });
Vue.component('v-select', VueSelect);
Vue.use(VueSimpleAlert);

require("select2");
require("select2/dist/css/select2.css");

require("./bootstrap");

require("./util/polyfill-foreach");

require("./partials/instrument-details");
require("./partials/advanced-search");
require("./partials/sidebar");
require("./partials/authorizable");
require("./partials/upload");
require("./partials/checkbox-table");

if (document.getElementById("vue-table")) {
    require("./generic/table");
}

require("./charts/doughnut");
require("./charts/barchart");

// this is an example for the /widgets page
const funds = document.querySelectorAll(".fund-list .fund");

for (let fundName in funds) {
    if (funds.hasOwnProperty(fundName)) {
        let fund = funds[fundName];
        fund.querySelector("input[type=checkbox]").addEventListener("change", e => {
            if (e.target.checked) {
                fund.classList.add("selected");
            } else {
                fund.classList.remove("selected");
            }
        });
    }
}
// end example

$(function () {
    $(".select2").select2({ width: 'resolve' });

    const sidebar = document.querySelector(".left-sidebar");
    const main = document.querySelector(".left-sidebar-primary");

    if (!sidebar.classList.contains("show-primary")) {
        main.addEventListener("mouseenter", function () {
            sidebar.classList.add("show-primary");
        });
        main.addEventListener("mouseleave", function () {
            sidebar.classList.remove("show-primary");
        });
    }
});

import VueTable from "./generic/table/VueTable";

document.querySelectorAll(".vue-table-wrapper").forEach(el => {
    new Vue({
        el,
        components: {VueTable},
    });
});
