import Vue from "vue";
import Table from "./table/Table";

const tables = document.querySelectorAll(".vue-table, #vue-table");

tables.forEach(el => {
    new Vue({
        el: "#vue-table",

        components: { Table },

        data: {
            tableSettings: null,
        },

        template: '<Table :tableSettings="tableSettings" />',

        created() {
            const element = document.querySelector("#vue-table");
            this.tableSettings = JSON.parse(element.dataset.tableSettings);
        }
    });
});
